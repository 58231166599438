<template>
    <form class="form" @submit.prevent="addArea">
        <div class="form__inputs-content">
            <div class="form__input-form">
                <label class="form__input-label form--required">Area Name</label>
                <input type="text" placeholder="Pool Area, Food Area..." class="form__input" v-model="area.name">

                <div class="form__input-error" v-if="errors.fields.name">
                    {{ errors.fields.name[0] }}
                </div>
            </div>

            <div class="form__input-form">
                <label class="form__input-label form--required">Floor</label>
                <select class="form__input" v-model="area.floorId">
                    <option value="">Select Floor Location</option>
                    <option :value="floor.id" v-for="floor in floors" :key="floor.id">
                        {{ floor.name }} {{ floor.prefix }}
                    </option>
                </select>

                <div class="form__input-error" v-if="errors.fields.floorId">
                    {{ errors.fields.floorId[0] }}
                </div>
            </div>
        </div>

        <div class="form__buttons">
            <button class="button button--success" v-if="!buttonLoader">
                <img src="@/assets/icons/vuesax/linear/add-circle.svg" alt="">
                Add Area
            </button>

            <ButtonTextLoader button-class="button button--success button-text-loader--content-width"
                loader-text="Verifying Information" loader-class="button-text-loader__loader-primary"
                :is-loading="buttonLoader" />
        </div>
    </form>

    <Notifications ref="notification" />
</template>

<script setup>
import { ref, reactive, onMounted } from 'vue'
import { areasServices } from '../Services/AreasServices.js'
import Notifications from '@/common/Notifications.vue'
import { sweetAlerts } from '../../../helpers/SweetAlert.js'
import { useRouter } from 'vue-router'
import ButtonTextLoader from '../../../common/ButtonTextLoader.vue'

const { addAreaService, getFloorsWithoutPagination, errors } = areasServices()
const notification = ref(null);
const area = reactive({ name: '', floorId: '' })
const floors = ref([])
const { sweetSuccess } = sweetAlerts()
const router = useRouter()
const buttonLoader = ref(false)

onMounted(() => { getFloors() })

async function addArea() {
    // * Show button loader
    buttonLoader.value = true

    const response = await addAreaService(area)

    if (response.hasErrors) {
        buttonLoader.value = false
        return notification.value.sendNotification('error', 'Error', response.message)
    }

    router.push('/hotel_setup/areas')

    return sweetSuccess(response.data.message)
}

async function getFloors() {
    const response = await getFloorsWithoutPagination()
    floors.value = response.data
}
</script>